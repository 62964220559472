<template>
	<!-- flex_column -->
	<div class="aircraftBrand_page" style="height: 100%">
		<!-- 飞机库组件 -->
		<hangar :isPhone="isPhone" :title="$t('trading.tab')"></hangar>
		<!-- 返回按钮 -->
		<div id="back" v-show="isPhone" @click="back"
			style="z-index: 999; position: fixed;top: 50%;left: 0;width: 50px;height: 50px;border-radius: 50%;">
			<img src="../assets/image/back_phone.png" style="height: 100%;width: 100%;" />
		</div>
		<div class="contactUS_page" style="padding: 8px">
			<div class="center">
				<div v-if="isShowReport" class="messageBox">
					<div class="msg-title">
						<ul>
							<li class="li1">
								<el-select v-model="value" size="mini" @change="changeSelect"
									:placeholder="$t('common.pleaseSelect')">
									<el-option v-for="item in options" :key="item.value" :label="item.label"
										:value="item.value"></el-option>
								</el-select>
							</li>
							<li class="li2" :class="language=='en'?'en_btn':''">
								<span :class="[current == index ? 'current title' : 'title']"
									@click.stop="changeTitle(index)" v-for="(item, index) in title" :key="index"
									style="padding: 2px;" v-html="item.name">

								</span>
							</li>
						</ul>
					</div>
					<div class="msg-body">
						<div class="ul">
							<div class="li" v-if="progressArr[0]" @click="changeLevel(0)"
								:class="[level >= 1 ? 'cursor current' : 'cursor']">
								<span>{{ progressArr[0].title }}</span>
								<span v-if="level >= 1 && detail[0]">{{ detail[0].createTime }}</span>
							</div>
							<div class="li" v-if="progressArr[1]" :class="[level >= 1 ? 'current li_d' : 'li_d']"></div>

							<div class="li" v-if="progressArr[1]" @click="changeLevel(1)"
								:class="[level >= 2 ? 'cursor current' : 'cursor']">
								<span>{{ progressArr[1]['title'] }}</span>
								<span v-if="level >= 2 && detail[1]">{{ detail[1].createTime }}</span>
							</div>
							<div class="li" v-if="progressArr[2]" :class="[level >= 2 ? 'current li_d' : 'li_d']"></div>

							<div class="li" v-if="progressArr[2]" @click="changeLevel(2)"
								:class="[level >= 3 ? 'cursor current' : 'cursor']">
								<span>{{ progressArr[2]['title'] }}</span>
								<span v-if="level >= 3 && detail[2]">{{ detail[2].createTime }}</span>
							</div>
							<div class="li" v-if="progressArr[3]" :class="[level >= 3 ? 'current li_d' : 'li_d']"></div>

							<div class="li" v-if="progressArr[3]" @click="changeLevel(3)"
								:class="[level >= 4 ? 'cursor current' : 'cursor']">
								<span>{{ progressArr[3]['title'] }}</span>
								<span v-if="level >= 4 && detail[3]">{{ detail[3].createTime }}</span>
							</div>
							<div class="li" v-if="progressArr[4]" :class="[level >= 4 ? 'current li_d' : 'li_d']"></div>

							<div class="li" v-if="progressArr[4]" @click="changeLevel(4)"
								:class="[level >= 5 ? 'cursor current' : 'cursor']">
								<span>{{ progressArr[4]['title'] }}</span>
								<span v-if="level >= 5 && detail[4]">{{ detail[4].createTime }}</span>
							</div>
						</div>
						<div class="ul row2" :class="progressArr.length>5?'':'hidden'">
							<div class="li" @click="changeLevel(5)"
								:class="[!progressArr[5]?'lishow':[level >= 6 ? 'cursor current' : 'cursor']]"
								:style="[progressArr[5] ==true ? 'opacity: 0;':' ']">
								<span>{{ progressArr[5]?progressArr[5]['title']:'' }}</span>
								<span v-if="level >= 6 && detail[5]">{{ detail[5].createTime }}</span>
							</div>
							<div class="li" :class="[!progressArr[6]?'lishow':[level >= 6 ? 'current li_d' : 'li_d']]">
							</div>

							<div class="li" @click="changeLevel(6)"
								:class="[!progressArr[6]?'lishow':[level >= 7 ? 'cursor current' : 'cursor']]">
								<span>{{ progressArr[6] ? progressArr[6]['title'] : '' }}</span>
								<span v-if="level >= 7 && detail[6]">{{ detail[6].createTime }}</span>
							</div>
							<div class="li" :class="[!progressArr[7]?'lishow':[level >= 7 ? 'current li_d' : 'li_d']]">
							</div>

							<div class="li" @click="changeLevel(7)"
								:class="[!progressArr[7]?'lishow':[level >= 8 ? 'cursor current' : 'cursor']]">
								<span>{{ progressArr[7] ? progressArr[7]['title'] : '' }}</span>
								<span v-if="level >= 8 && detail[7]">{{ detail[7].createTime }}</span>
							</div>
							<div class="li" :class="[!progressArr[8]?'lishow':[level >= 8 ? 'current li_d' : 'li_d']]">
							</div>

							<div class="li" @click="changeLevel(8)"
								:class="[!progressArr[8]?'lishow':[level >= 9 ? 'cursor current' : 'cursor']]">
								<span>{{ progressArr[8] ? progressArr[8]['title'] : '' }}</span>
								<span v-if="level >= 9 && detail[8]">{{ detail[8].createTime }}</span>
							</div>
							<div class="li" :class="[!progressArr[9]?'lishow':[level >= 9 ? 'current li_d' : 'li_d']]">
							</div>

							<div class="li" @click="changeLevel(9)"
								:class="[!progressArr[9]?'lishow':[level >= 10 ? 'cursor current' : 'cursor']]">
								<span>{{ progressArr[9] ? progressArr[9]['title'] : '' }}</span>
								<span v-if="level >= 10 && detail[9]">{{ detail[9].createTime }}</span>
							</div>

						</div>
					</div>
					<div class="footera zui-flex-row zui-center-center zui-p-b-20">
						<div class="zui-w-100">
							<div class="zui-p-y-10 zui-m-b-10 zui-p-x-20 process-desc">
								<p>{{ processPrefix }}{{$t('trading.mesg3')}}:</p>
								<viewer :images="[]">
									<div v-html="processDescription()" class="gg"
										style="width: 100%; padding:0 2%;">
											
									</div>
	
								</viewer>
								<!-- 	 -->
								<!-- <div v-html="processDescription()" class="gg" style="width: 100%; padding:0 2%;">
								</div> -->

								<!-- <p v-if="level > 0" ></p> -->
							</div>

							<div class="msgMiddle">

								<div class="scroll">
									<div v-for="item in message2" :key="item.id"
										style="margin-bottom: 10px;padding-bottom: 10px;border-bottom: 1px solid #ccc;">
										<div class="reply">
											{{item.userName?item.userName+"&nbsp;&nbsp;":''}}{{$t('trading.mesg4')}}：{{ item.commentary }}
										</div>
										<div class="commentary" style="">{{$t('trading.mesg5')}}：{{ item.reply }}</div>
									</div>
								</div>

								<!-- <el-input type="textarea" :autofocus="true" placeholder="评注" v-model="desc"></el-input>-->
							</div>

							<div class="msgFooter">
								<el-input type="textarea" resize="none" :disabled="this.level-1<this.currentLevel"
									:autofocus="true" :placeholder="$t('trading.mesg5')" v-model="desc"></el-input>
							</div>
							<div class="msg-Footer">
								<el-button class="jinButton jinBorder btn" @click="addMsg"
									:disabled="this.level-1<this.currentLevel" size="mini" type="primary">
									{{$t('trading.mesg6')}}
								</el-button>
							</div>
						</div>
					</div>
				</div>
				<div v-else class="zui-text-white zui-font-md">{{$t('trading.mesg7')}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	// import $config from "../api/config.js"
	import hangar from '../components/hangarCom';
	import {
		mapState,
		mapMutations
	} from 'vuex';
	import {
		getAir,
		frontProgress2,
		addCommentary,
		airProgress
	} from '../api/api';
	// 飞机品牌页面
	export default {
		components: {
			hangar
		},
		data() {
			return {
				controls: false,
				dotli: [{
						id: 1
					},
					{
						id: 2
					},
					{
						id: 3
					}
				],
				isPhone: false,
				gh: '<h4></h4>',
				message2: [],
				processPrefix: '',
				desc: '',
				message: '',
				currentLevel: 0,
				title: [{
						name: this.$t('trading.tab3_title1')
					},
					{
						name: this.$t('trading.tab3_title2')
					},
					{
						name: this.$t('trading.tab3_title3')
					}
				],
				currentId: '',
				current: 0,
				currentIndex: 0,
				options: [],
				value: '',
				level: 0,
				detail: [],
				sellId: 0,
				progressApi: ['airProgressFindplane', 'airProgressSaleplane', 'airProgressHostingplane'],
				progressArr: [{
						title: ''
					},
					{
						title: ''
					},
					{
						title: ''
					},
					{
						title: ''
					},
					{
						title: ''
					},
					{
						title: ''
					},
					{
						title: ''
					},
					{
						title: ''
					},
					{
						title: ''
					},
					{
						title: ''
					}
				]
			};
		},
		watch: {
			language() {
				this.$nextTick(() => {
					this.title = [{
							name: this.$t('trading.tab3_title1')
						},
						{
							name: this.$t('trading.tab3_title2')
						},
						{
							name: this.$t('trading.tab3_title3')
						}
					]
				})
			}
		},
		// filters: {
		// 	swiperImg: function(value) {
		// 		// console.log(value);
		// 		if ((value.search("http") != -1) && (value.search("https") != -1)) {
		// 			return value
		// 		} else {
		// 			return $config.apiUrl + '/' + value
		// 		}
		// 	}
		// },
		created: function() {
			if (/Android|webOS|iPhone|iPad|BlackBerry/i.test(navigator.userAgent)) {
				this.isPhone = true;
			} else {
				this.isPhone = false;
			}

		},
		computed: {
			...mapState(['userInfo', 'token', 'isLogin', 'language']),
			isShowReport() {
				if (this.userInfo.userAuthority) {
					let isShow = this.userInfo.userAuthority.indexOf('process');
					return isShow != -1 ? true : false;
				} else {
					return false;
				}
			}
		},

		beforeMount: async function() {
			await this.progress();
			await this.getData();
		},
		methods: {
			...mapMutations(['logOut']),
			emptyMessage() {
				this.message = this.$t('trading.mesg8');
			},
			processDescription() {
				let data = this.detail;
				// console.log(this.progressArr)
				if (data[this.currentLevel]) {
					
					//console.log(data[this.currentLevel].processDescription.replace(/<img/g,"<img style='max-width:100% !important'"))
					let handleImg=data[this.currentLevel].processDescription.replace(/<img/g,"<img style='max-width:100% !important'")
					
					return handleImg.replace(/white-space: pre;/g,"");
					
				}
			},
			changeLevel(index) {

				// console.log(this.detail,11)
				// this.emptyMessage();
				// console.log(index, this.level);
				this.controls = true
				// console.log(this.controls)
				if (this.level != 0) {
					this.currentLevel = index;
					// console.log(this.currentLevel,index,this.level)
					// if(this.currentLevel == 0){
					// 	console.log("eeeeeeee")
					// 	this.controls=true
					// }
					if (index < this.level) {
						this.sellId = this.detail[index].id;
						this.message2 = this.detail[index].airProgressChrildList

					} else {
						this.sellId = '';
						this.message2 = []
					}
					//获取当前流程评注
					this.processPrefix = this.progressArr[index]['title'];
					// this.getMessage(index);

				}
				// if(this.currentLevel==0){

				// 	this.sellId = this.detail[0].id;
				// 	this.message2 = this.detail[0].airProgressChrildList
				// 	//获取当前流程评注
				// 	this.processPrefix = this.progressArr[0]['title'];
				// 	// this.getMessage(index);
				// }
			},
			getMessage(index) {
				this.message = this.$t('trading.mesg4') + this.detail[index].commentary + this.$t('trading.mesg9') + (this
					.detail[index].reply || this.$t('trading.mesg10'));
			},

			progress() {
				// console.log(this.current);
				// console.log(this.progressArr)
				airProgress[this.progressApi[this.current]]().then(res => {
					// console.log('流程', res.result.records);

					this.progressArr = res.result.records;
					this.progressArr = this.detail
					// console.log(this.progressArr)
					// var dotlis=5 - this.progressArr.length % 5
					// for(let i = 0;i<dotlis;i++){
					// 	// this.progressArr=[...this.progressArr,{show:true}]
					// }
					// console.log(res,this.progressArr,22222222)
					// console.log(this.progressArr)
					// console.log(dotlis)

				});
				//console.log(this.detail)
			},
			changeSelect(index = 0) {
				this.controls = false

				if (this.level > 0) {
					// console.log(this.currentLevel,this.controls,333)
					//this.currentLevel=this.level-1
					if (!this.controls) {
						this.currentLevel = this.level - 1
					}

				}
				// this.emptyMessage();
				this.currentId = this.options[index].id;
				this.currentIndex = index;

				this.getSell(this.currentId);
			},
			async changeTitle(index = 0) {
				this.controls = false
				// this.emptyMessage();
				this.current = index;
				this.currentLevel = 0;
				this.level = 0;
				this.detail = [];
				this.currentId = '';
				this.message2 = [];
				this.processPrefix = '';
				this.sellId = 0;
				await this.progress();
				await this.getData();
			},
			//获取飞机
			getData() {
				this.options = [];
				this.value = '';
				this.currentId = '';
				let queryData = {};
				queryData.userId = this.userInfo.id;
				let _this = this;
				let apiArr = ['getAirFindInfo', 'getAirSellInfo', 'getAirPlaneTrusteeship'];
				getAir[apiArr[this.current]](queryData).then(res => {

					// res.progress=
					if (res.code == 200) {
							// console.log(res.result.length,8888,'aaa---');
						if (res.result.length > 0) {
							this.$zui.each(res.result, (index, item) => {
								if (index == 0) {
									_this.value = item.planeType;
									this.currentId = item.id;
									_this.getSell(item.id);
								}
								let temp = {};
								temp.label = item.planeType;
								temp.value = index;
								temp.id = item.id;
								temp.progress = item.progress
								_this.options.push(temp);
							});
				
						}
					} else {
						this.$message({
							message: res.message,
							showClose: true,
							customClass: 'hahaha',
							type: 'error',
							duration: 2000,
							offset: 20
						});
						// console.log(res.message);
					}
				});
			},
			//获取交易流程
			getSell(id) {
				let queryData = {};
				queryData.type = this.current + 1;
				queryData.id = id;

				frontProgress2(queryData).then(res => {
					if (res.code == 200) {
						//console.log(200,res)
						this.detail = res.result;
						res.result.forEach((item, index) => {
							if (item.isCurrent == 1) {
								this.currentLevel = index
								this.level = index + 1
							}
						})

						//console.log(this.currentLevel,this.level)
						this.progress()
						// console.log(this.options,this.currentIndex,222222)
						// for(let i=0;i<this.progressArr.length;i++){
						// 	// console.log(this.progressArr[i].id,this.options[this.currentIndex].progress)
						// 	if(this.progressArr[i].id==this.options[this.currentIndex].progress){
						// 		// this.level = 1
						// 		// console.log(this.progressArr[i].level,3333333)
						// 		this.level=i+1
						// 	}
						// }

						// console.log(111111111,this.detail);
						// console.log('sell', this.detail);
						// let length = this.detail.length;
						// console.log(this.currentLevel);
						// if (length > 0) {
						// 	// this.level = length;
						// 	// console.log(this.level,11111111111)
						// 	if (this.currentLevel == 0) {
						// 		this.currentLevel = length - 1;
						// 	}
						// }

						// if(this.level>0){
						// 	// console.log(this.currentLevel,this.controls,333)
						// 	//this.currentLevel=this.level-1
						// 	if(!this.controls){
						// 		this.currentLevel=this.level-1
						// 	}

						// }
						this.sellId = this.detail[this.currentLevel].id
						//获取一个流程的评注
						this.message2 = this.detail[this.currentLevel].airProgressChrildList
						this.processPrefix = this.detail[this.currentLevel]['title'];
						// console.log(111111111,this.processPrefix,this.detail,)
						// console.log(this.currentId,this.currentLevel);

						// this.getMessage(length - 1);
					} else {
						this.$message({
							message: res.message,
							showClose: true,
							customClass: 'hahaha',
							type: 'error',
							duration: 2000,
							offset: 20
						});
						// console.log(res.message);
					}
				});
			},
			addMsg() {
				if (this.currentId == '') {
					this.$message({
						message: this.$t('trading.mesg1'),
						showClose: true,
						type: 'error',
						customClass: 'hahaha',
						duration: 2000,
						offset: 20
					});
					return false;
				}
				if (this.desc == '') {
					this.$message({
						message: this.$t('trading.mesg2'),
						showClose: true,
						type: 'error',
						customClass: 'hahaha',
						duration: 2000,
						offset: 20
					});
					return false;
				}
				let queryData = {};
				queryData.type = this.current + 1;
				queryData.id = this.sellId;
				queryData.commentary = this.desc;
				// console.log(queryData);
				addCommentary(queryData).then(res => {
					// console.log(res);
					if (res.code == 200) {
						this.$message({
							message: this.$t('common.submitSuccess'),
							showClose: true,
							type: 'success',
							customClass: 'hahaha',
							offset: 20,
							duration: 2000,
							onClose: () => {
								this.desc = '';
								this.getSell(this.currentId);
							}
						});
					} else {
						this.$message({
							message: res.message,
							showClose: true,
							customClass: 'hahaha',
							type: 'error',
							duration: 2000,
							offset: 20
						});
						// console.log(res.message);
					}
				});
			}
		}
	};
</script>

<style lang="scss" scoped>
	@import '../assets/scss/config.scss';
	@import '../assets/scss/mixin.scss';

	.gg p img{
		width: 100% !important;
	}
	.hidden {
		opacity: 0;
		pointer-events: none;
	}

	.jinButton {
		text-transform: uppercase;
	}

	.en_btn {
		.title {
			text-transform: lowercase;
			font-variant: small-caps;
			font-size: 12px !important;
		}
	}

	.lishow {
		opacity: 0;
		pointer-events: none;
	}

	.contactUS_page {
		width: 100%;
		// height: 100%;
		height: calc(100% - 130px);
		background-image: url('../assets/image/14.jpg');
		background-position: 50%;
		background-size: cover;
		background-repeat: no-repeat;
		background-color: rgba($color: #000000, $alpha: 1);
		display: flex;
		flex-direction: column;
		align-items: center;

		// justify-content: flex-end;
		.center {
			min-height: 400px;
			margin: 20px 0;
			background-color: rgba($color: #ffffff, $alpha: 0.3);
			width: 50%;
			box-sizing: border-box;
			padding: 30px;

			.messageBox {
				width: 100%;
				height: 600px;
				flex: 1;
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				.msg-title {
					height: 50px;
					padding: 15px 20px 0px;
					// background-color: black;
					box-sizing: border-box;

					ul {
						display: flex;
						justify-content: space-between;
						align-items: center;
						list-style: none;
						margin: 0;
						padding: 0;
						height: 100%;
						width: 100%;
						border-bottom: 1px solid rgba($color: #000000, $alpha: 0.6);

						li {
							margin: 0;
							padding: 0;
							// flex: 1;
							height: 100%;
							display: flex;
							align-items: center;

							.title {
								background-color: #f0edf1;
								color: #000000;
								width: 30%;
								height: 100%;
								cursor: pointer;
								display: flex;
								justify-content: center;
								align-items: center;
								font-size: 14px;
								border-radius: 5px;
								margin-left: 3%;
								text-align: center;

								&.current {
									background-color: #000000;
									color: #f0edf1;
								}
							}

							&.li1 {
								flex-basis: 30% !important;
							}

							&.li2 {
								flex-basis: 62% !important;
							}

							&.li3 {
								display: flex;
								justify-content: flex-end;
							}
						}
					}
				}

				.msg-body {
					// flex: 1;
					width: 100%;
					padding: 0 30px;
					box-sizing: border-box;

					// div.ul.row2{
					// 	div.li{
					// 		&:nth-child(2n) {
					// 			height: 0;
					// 			flex: 0;
					// 			flex-basis:17.5%  ;
					// 			// flex-basis: calc(20% - 10px) !important;
					// 			// width: 20% !important;
					// 			background-color: transparent !important;
					// 		}
					// 	}
					// }
					div.ul {
						width: 100%;
						height: 70px !important;
						list-style: none;
						padding: 0;
						margin: 10px auto;
						display: flex;
						flex-wrap: wrap;
						justify-content: flex-start;
						align-items: center;

						div.li {
							position: relative;
							background-color: #b1afb2;
							border-bottom: 4px dashed #b1afb2;
							margin-top: 15px !important;

							// height: 220px;
							&:nth-child(2n + 1) {
								width: 18px;
								height: 18px;
								border-radius: 100%;

								span {
									position: absolute;
									content: '';
									width: 80px;
									text-align: center;

									left: 50%;
									margin-left: -40px;
									font-size: 10px;
									// color: #dbb96e;
									color: #333;

									&:first-child {
										top: -25px !important;
									}

									&:last-child {
										bottom: -38px !important;
									}
								}
							}

							&:nth-child(2n) {
								height: 0;
								// flex-basis:17.5%  ;
								flex: 1;
								// width: 20% !important;
								background-color: transparent !important;
							}

							&.current {
								background-color: #dbb96e;
								border-bottom: 4px dashed #dbb96e;

								&:nth-child(2n) {
									border-bottom-color: #dbb96e;
								}
							}
						}
					}
				}

				.footera {
					.zui-w-100 {
						width: 94%;
					}

					.process-desc {
						height: 100px;
						box-sizing: border-box;
						background-color: rgba($color: #ffffff, $alpha: 0.5);
						overflow-y: scroll;
						overflow-x: hidden;
						.gg{
							width: 100%;
							display: block;
							p{
								display: inline-block;
								color: red !important;
								img{
									max-width: 100% !important;
									height: auto;
								}
								video{
									max-width: 100% !important;
									height: auto;
								}
							}
							
						}
						
					}

					.msgMiddle {
						margin-bottom: 10px;

						.scroll {
							height: 80px;
							overflow-y: scroll;
						}

						.commentary {
							margin-top: 5px;
							// padding-left: 10px;
							text-align: left;
						}

						.reply {
							// margin-left: 20px;
							text-align: left;
						}
					}

					.msg-Footer {
						margin-top: 10px;
						display: flex;
						justify-content: center;

						.btn {
							height: 30px;
							display: flex;
							align-items: center;
							justify-content: center;
						}
					}

					.msgMiddle,
					.msgFooter {
						background: #f5f5f5;
						border: 1px solid #d7d7d7;
						box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.2);
						padding: 10px;
						box-sizing: border-box;
						position: relative;

						/deep/ .el-textarea {
							height: 100% !important;

							.el-textarea__inner {
								background-color: #f5f5f5;
								border: none;
								//text-transform: uppercase;
							}
						}


						.btn {
							position: absolute;
							bottom: 5%;
							right: 2%;
							height: 30px;
							padding: 0;
							margin: 0;
						}
					}
				}

				.top {
					width: 100%;
					height: 60px;
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}
		}
	}

	@media screen and (max-width:1440px) {
		.contactUS_page {
			.center {
				/deep/ .messageBox {
					.msg-title {
						ul {
							li {

								&.li2 {
									flex-basis: 100% !important;
								}
							}
						}
					}
				}
			}
		}

		.en_btn {
			.title {
				font-size: 10px !important;
			}
		}
	}

	// 手机适配
	@media screen and (max-width: 1024px) {
		.contactUS_page {
			padding: 5px !important;

			.center {
				padding-top: 10px !important;
				padding: 0;
				margin: 0;
				width: 100%;

				/deep/ .messageBox {
					height: 660px;

					.footera {
						.zui-w-100 {
							width: 94%;
						}
					}

					.msg-title {
						ul {
							li {
								.title {
									font-size: 10px !important;
								}

								&.li2 {
									flex-basis: 100% !important;
								}
							}
						}
					}

					.msg-body {
						// flex: 1;
						width: 100%;
						padding: 0 32px;
						box-sizing: border-box;

						div.ul {
							width: 100%;
							height: 100px !important;
							list-style: none;
							padding: 0;
							margin: 0;
							display: flex;
							flex-wrap: wrap;
							justify-content: flex-start;
							align-items: center;

							div.li {
								position: relative;
								background-color: #b1afb2;
								border-bottom: 4px dashed #b1afb2;
								margin-top: 45px;

								&:nth-child(2n + 1) {
									width: 18px;
									height: 18px;
									border-radius: 100%;

									span {
										position: absolute;
										content: '';
										width: 80px;
										text-align: center;

										left: 50%;
										margin-left: -40px;
										font-size: 6px !important;
										// color: #dbb96e;
										color: #333;

										&:first-child {
											top: -20px;
										}

										&:last-child {
											bottom: -20px;
										}
									}
								}

								&:nth-child(2n) {
									height: 0;
									width: 17% !important;
									background-color: transparent !important;
								}

								&.current {
									background-color: #dbb96e;
									border-bottom: 4px dashed #dbb96e;

									&:nth-child(2n) {
										border-bottom-color: #dbb96e;
									}
								}
							}
						}
					}
				}

				/dee/ .el-form-item__label {
					text-align: left !important;
				}
			}
		}
	}

	@media screen and (max-width: 320px) {
		.contactUS_page {

			.center {


				/deep/ .messageBox {


					.msg-body {

						div.ul {
							height: 90px !important;

							div.li {
								&:nth-child(2n + 1) {
									span {
										&:last-child {
											transform: scale(0.9);
											bottom: -45px !important;
										}
									}
								}

								&:nth-child(2n) {
									height: 0;
									width: 17% !important;
								}


							}
						}
					}
				}

			}
		}
	}
</style>
